import * as React from "react";
import SEO from "../components/SEO";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/EE_Cover.jpg";
import TunisiaJobs15 from "../assets/img/enabling_environment_1.jpg";
import TunisiaJobs16 from "../assets/img/enabling_environment_2.jpg";
import TunisiaJobs17 from "../assets/img/enabling_environment_3.jpg";
import TunisiaJobs18 from "../assets/img/enabling_environment_4.jpg";
import TunisiaJobs19 from "../assets/img/enabling_environment_5.jpg";
import TunisiaJobs20 from "../assets/img/enabling_environment_6.jpg";

const EnablingEnvironment = () => {
  return (
    <>
      <SEO title="Enabling Environment | Visit Tunisia Project" />
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Enabling Environment
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Creating a climate <span> for sustained growth</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                about="/en/Assistance_To_Enterprises"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="sector-vision">
                        <div className="container">
                          <div className="row">
                            <div className="card-wrapper">
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="inner-card">
                                  <h3>THE SECTOR</h3>
                                  <p>
                                    Tunisia’s tourism sector is a major source
                                    of employment, providing direct jobs for
                                    close to half a million people. As the
                                    market evolves, however, industry workers
                                    need new service skills and business
                                    techniques to thrive. Effective avenues for
                                    private sector advocacy and communication
                                    with the government are also essential to
                                    the industry’s continued growth.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay
                              >
                                <div className="inner-card">
                                  <h3>VISIT TUNISIA’S VISION AND STRATEGY</h3>
                                  <p>
                                    The USAID Visit Tunisia Activity strengthens
                                    Tunisia’s tourism climate by promoting
                                    international quality standards, policy
                                    dialogue, and industry advocacy for policy,
                                    regulatory, and institutional enhancements.
                                    Our work includes training and support
                                    services to government agencies, SMEs, and
                                    tourism federations and associations,
                                    including in the use of new digital
                                    solutions. We aim to see Tunisia build a
                                    competitive tourism sector that is
                                    sustainable, resilient, and diverse.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Our Work
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  Visit Tunisia works with public and private
                                  stakeholders to
                                  improve Tunisia’s enabling policy,
                                  regulatory environment, and training
                                  mechanisms to foster competitiveness and
                                  growth in tourism. This work covers six broad
                                  areas:
                                </p>
                              </div>
                              <div className="right-side d-flex justify-content-md-end">
                                <div className="ticket">
                                  <h3>
                                    <a href="/where-we-work" target="_blank">
                                      Where We Work
                                    </a>
                                  </h3>
                                  <a
                                    className="underlined-link"
                                    href="/where-we-work"
                                    target="_blank"
                                  >
                                    Learn More{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="grants-section"
                            style={{ height: "60%", marginTop: "25px" }}
                          >
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate our-work-image"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs15}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Strengthening tourism associations and
                                    federations
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We work with tourism associations to
                                      improve their business planning, advocacy,
                                      membership services and benefits, and
                                      overall effectiveness.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={100}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img src={BannerEffect} alt="" />
                                </div> */}
                                <img
                                  src={TunisiaJobs16}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Improving quality, safety, and professional
                                    standards
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We assist Tunisia's National Tourism
                                      Office (ONTT) and tourism industry
                                      federations and associations to review and
                                      improve the standards and inspection
                                      process that govern the standards of
                                      quality in the tourism industry.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs17}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Industry-based training and certification
                                    (Pathways to Professionalism)
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We provide industry-based competency
                                      training and certification for human
                                      capital in the tourism industry.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs18}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Tourism SME Training and Support Activity.
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We provide business training and
                                      customized technical assistance to SMEs to
                                      establish new market linkages and improve
                                      profitability.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs19}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Improving data-driven decision-making</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We support government entities, tourism
                                      federations and private sector partners to
                                      collect and analyze data to better
                                      understand tourism impact, marketing
                                      performance, and return on investments.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs20}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Policy reform</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We work with Tunisia’s transport ministry
                                      and other relevant agencies to improve
                                      transport access into and throughout
                                      Tunisia.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--54 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              OUR GOALS
                            </div>
                          </span>
                          <h2></h2>
                          <ul className="pl-0">
                            <li>
                              <span>
                                Help train and certify at least 2,000 tourism
                                professionals throughout Tunisia
                              </span>
                            </li>
                            <li>
                              <span>
                                Help train and certify at least 100 trainers on
                                tourism and hospitality international quality
                                standards
                              </span>
                            </li>
                            <li>
                              <span>
                                Facilitate events to encourage public-private
                                dialogue in the tourism sector
                              </span>
                            </li>
                            <li>
                              <span>
                                Support the implementation of the Tourism
                                Satellite Account to improve decision-making in
                                the sector for both policymakers as well as
                                private industry
                              </span>
                            </li>
                            <li>
                              <span>
                                Assist 500 SMEs to increase revenues by 10%
                              </span>
                            </li>
                            <li>
                              <span>
                                Strengthen the capacity of Tunisian tourism
                                federations and associations to have sustainable
                                presence and advocate on behalf of their
                                members.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default EnablingEnvironment;
